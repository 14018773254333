import React from 'react';
import translations from '../locales/translations.json';

const LanguageSelector = ({ currentLanguage, setCurrentLanguage }) => {
  const handleChange = (e) => {
    const selectedLanguage = e.target.value;
    setCurrentLanguage(selectedLanguage);
    
    // Set cookie for selected language
    document.cookie = `selectedLanguage=${selectedLanguage}; path=/`;

    // Reload the website after setting the cookie
    window.location.reload();
  };

  // Set default cookie to EN if none exists
  React.useEffect(() => {
    const currentCookie = document.cookie
      .split('; ')
      .find((row) => row.startsWith('selectedLanguage='));

    if (!currentCookie) {
      document.cookie = `selectedLanguage=EN; path=/`;
    }
  }, []);

  return (
    <div className="language-selector">

      <select id="language" onChange={handleChange} value={currentLanguage}>
        {Object.keys(translations).map((lang) => (
          <option key={lang} value={lang}>
            {lang === 'EN' ? 'English' : 'Slovak'}
          </option>
        ))}
      </select>
    </div>
  );
};

export default LanguageSelector;

let data = [
  {
    subject: "What is FamSketch?",
    // description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime.",
    answer: "FamSketch is a private social network designed exclusively for families. It allows family members to record, share, and organize family memories, including photos, videos, and important life events, in a secure environment.",
    id: 1
  },
  {
    subject: "How is FamSketch different from other social networks?",
    // description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime.",
    answer: 'FamSketch is specifically built for family connections. Unlike general social networks, FamSketch provides tools tailored to family needs, such as a family tree builder, the ability to create a digital family chronicle, and privacy settings that restrict content sharing to your family members.',
    id: 2
  },
  {
    subject: "What type of content can I share on FamSketch?",
    // description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime.",
    answer: "FamSketch allows you to share photos, videos, and audio recordings, as well as written updates. You can post memories from special events like weddings or family gatherings and even create legacy posts to capture your life story.",
    id: 3
  },
  {
    subject: "Can I invite extended family and family friends to join FamSketch?.",
    // description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime.",
    answer: "Yes! FamSketch allows you to connect not only with direct family members but also with extended family and family friends. This feature helps you build a network that reflects your entire family circle.",
    id: 4
  },
  {
    subject: "How secure is my information on FamSketch?",
    // description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime.",
    answer: "FamSketch prioritizes privacy and security. We use encryption to protect your data and allow only invited family members to access your content. We also offer customizable privacy settings, so you control who sees your posts.",
    id: 5
  },
  {
    subject: "Is FamSketch free to use?",
    // description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime.",
    answer: "FamSketch offers both free and premium membership options. The free version includes basic features, while premium plans may provide additional functionalities like enhanced family tree tools, extra storage, and the ability to print your profile in an elegant form.",
    id: 6
  },
  {
    subject: "Will my family be notified if I post something new?",
    // description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Maxime.",
    answer: "Yes, FamSketch has an in-app notification feature. When you share new content, your family members will receive notifications to keep them updated on your posts and events.",
    id: 7
  }
]

export const useFAQData = () => {
  return data
}

import React, { useEffect, useState } from 'react'
import './SideBar.css'
import translations from '../../../../locales/translations.json';

function SideBar({acceptRequest,deleteRequest,unFollow,deleteFollower,followers,followings,requests,showProfileModel}) {
    const [showFollowers, setShowFollowers] = useState(true)
    const [showFollowings, setShowFollowings] = useState(false)
    const [showRequests, setShowRequests] = useState(false)
    // console.log(followers)

    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
        return null;
      };
      const savedLanguage = getCookie('selectedLanguage');
      const initialLanguage = savedLanguage ? savedLanguage.toUpperCase() : undefined;
    
      const [currentLanguage, setCurrentLanguage] = useState(initialLanguage || 'EN');
      const [t, setT] = useState(translations[currentLanguage]);
    
      useEffect(() => {
        // If no saved language, set English by default
        if (!savedLanguage) {
          document.cookie = `selectedLanguage=en; path=/`;
        }
    
        // Update translation based on the current language
        setT(translations[currentLanguage]);
      }, [currentLanguage, savedLanguage]);

      
    const renderData = (user)=>{
        return <div className='other-user' key={user.email}>
            <div onClick={()=>{showProfileModel(null,user.email)}}>
                <p>{t.name}: {`${user.first_name} ${user.last_name}`}</p>
                <p>{t.email}: {user.email}</p>
            </div>
            {showFollowers?<button onClick={()=>{deleteFollower(user.email)}} className='delete-user' ><i className='bx bx-plus rotated'></i></button>
            :showFollowings?<button onClick={()=>{unFollow(user.email)}} className='delete-user' ><i className='bx bx-plus rotated'></i></button>
            :<button onClick={()=>{deleteRequest(user.email)}} className='delete-user' ><i className='bx bx-plus rotated'></i></button>
            }
            {showRequests &&
            <button onClick={()=>{acceptRequest(user.email)}} className='accept-user' ><i className='bx bx-check'></i></button>
            }
        </div>
    }
  return (
    <section className='app-element miscellaneous' id='ffr'>
        <div className='misc-nav'>
            <button className={`${showFollowers?"":"disable"}`} onClick={()=>{
                setShowFollowers(true);
                setShowFollowings(false);
                setShowRequests(false);
            }}>{t.followers}</button>
            <button className={`${showFollowings?"":"disable"}`} onClick={()=>{
                setShowFollowers(false);
                setShowFollowings(true);
                setShowRequests(false);
            }}>{t.followings}</button>
            <button className={`${showRequests?"":"disable"}`} onClick={()=>{
                setShowFollowers(false);
                setShowFollowings(false);
                setShowRequests(true);
            }}>{t.request}</button>
        </div>
        <div className='misc-body'>
            {showFollowers?followers.map(follower=>renderData(follower)):
            showFollowings?followings.map(following=>renderData(following)):
            requests.map(request=>renderData(request))}
        </div>
    </section>
  )
}

export default SideBar
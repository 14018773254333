import { useRef, useEffect, useState } from "react";
import './Ai.css';
import axios from '../../../axios.js'
import { toast } from "react-toastify";
import translations from '../../../locales/translations.json';

const AutoComplete = (userData) => {
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const mapRef = useRef();
  const markerRef = useRef();
  const chatbotinner = useRef(null);
  const user = userData.userData.user
  var places_arr = []
  const [places, setPlaces] = useState([]);
  const [messages, setMessages] = useState([])

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  };

  const savedLanguage = getCookie('selectedLanguage');
  const initialLanguage = savedLanguage ? savedLanguage.toUpperCase() : undefined;

  const [currentLanguage, setCurrentLanguage] = useState(initialLanguage || 'EN');
  const [t, setT] = useState(translations[currentLanguage]);

  useEffect(() => {
    // If no saved language, set English by default
    if (!savedLanguage) {
      document.cookie = `selectedLanguage=en; path=/`;
    }

    // Update translation based on the current language
    setT(translations[currentLanguage]);
  }, [currentLanguage, savedLanguage]);


  const handleKeyDownMessage = (event) => {
    if (event.key === 'Enter') {
      appendUserMessage()
    }
  };

  const options = {
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["establishment"]
  };

  function clearSearch() {
    inputRef.current.value = '';
    setPlaces([]);
  }
  
  function handleKeyDown(event) {
    if (event.key === 'Enter') {
      const selectedPlace = autoCompleteRef.current.getPlace();
      // Rest of the existing logic for handling the selected place
    }
  }

  function getRatingHTML(rating) {
    if (rating === "" || rating === 0) {
      return ``;
    }

    let fullStars = Math.floor(rating);
    let halfStar = rating % 1 >= 0.5 ? 1 : 0;
    let emptyStars = 5 - fullStars - halfStar;

    let starsHTML = '';

    for (let i = 0; i < fullStars; i++) {
      starsHTML += `<div class="star"><img src="/full_star.png" alt="Full Star" /></div>`;
    }

    if (halfStar) {
      starsHTML += `<div class="star"><img src="/half_star.png" alt="Half Star" /></div>`;
    }

    for (let i = 0; i < emptyStars; i++) {
      starsHTML += `<div class="star"><img src="/empty_star.png" alt="Empty Star" /></div>`;
    }

    return starsHTML;
  }

  function createList() {
    // running animation and removing classes


    var ul = document.getElementById('list_of_results');
    document.getElementById('list_of_results').innerHTML = '';
    for (var i = 0; i < places_arr.length; i++) {
      const place = places_arr[i];
      const a = document.createElement('a');
      a.href = `${place.url}`;
      a.target = '_blank';
      a.className = 'a_tag';
      a.rel = 'noreferrer';
      const li = document.createElement('li');
      li.className = 'outer_result_box';
      li.innerHTML = `
        <p class="name_of_place">${place.name}</p>
        <span class="vicinity">${place.vicinity}</span>
        <div class="rating_outer">
          <div class="rating">${place.rating ? place.rating : ''
        }</div>
          <div class="stars">`+ getRatingHTML(place.rating) + `
            
          </div>
          <div class="reviews">${place.user_ratings_total ? '(' + place.user_ratings_total + ')' : ''
        }</div>
        </div>
        <div class="phone_closed_open">
          <div class="closed_open ${place.openOrNot ? 'green' : 'red'}">
            ${place.openOrNot ? 'Open' : 'Closed'}
          </div>
          <div class="phone">
            <span>${place.formatted_phone_number ? place.formatted_phone_number : ''}</span>
          </div>
        </div>

      `;
      a.appendChild(li);
      ul.appendChild(a);
    }


    if (document.getElementById('searchbar').classList.contains('preSearch')) {
      var all_elements_to_be_hid = document.getElementsByClassName('hideAfter')
      for (var i = 0; i < all_elements_to_be_hid.length; i++) {
        all_elements_to_be_hid[i].style.display = 'none';
      }
      document.getElementById('loading').style.display = 'none';
      document.getElementById('searchbar').classList.remove('preSearch');
      document.getElementById('searchbar_inner').classList.remove('preSearchInner');
      document.getElementById('center_ai').classList.remove('preSearchHidden');
      document.getElementById('right_ai').classList.remove('preSearchHidden');

      if (window.innerWidth < 600) {
        document.getElementById('chat_nav').style.display = 'flex';
      }
    }

    document.getElementById('hidden_element_with_data').value = JSON.stringify(places_arr);
  }

  function openChatPhone() {
    document.getElementById('left_ai').style.display = 'none';
    document.getElementById('center_ai').style.display = 'none';
    document.getElementById('right_ai').style.display = 'flex';
    document.getElementById('chat_nav').style.display = 'none';
    document.getElementById('map_nav').style.display = 'flex';
  }

  function openMapPhone() {
    document.getElementById('left_ai').style.display = 'flex';
    document.getElementById('center_ai').style.display = 'none';
    document.getElementById('right_ai').style.display = 'none';
    document.getElementById('chat_nav').style.display = 'flex';
    document.getElementById('map_nav').style.display = 'none';
  }

  function appendUserMessage() {

    var user_message = document.getElementById("message").value
    if (user_message == "") {
      return;
    }
    document.getElementById("message").value = ""
    var div = document.createElement('div')
    div.className = "left_message"
    var div_top_of_message = document.createElement('div')
    div_top_of_message.className = "top_of_message"
    var img = document.createElement('img')
    if (user.image) {
      img.src = user.image
    }
    else {
      img.src = "/profilePicture-default.png"
    }
    img.className = "img_of_person"
    var name = document.createElement('div')
    name.className = "name"
    name.innerHTML = user.username
    div_top_of_message.appendChild(img)
    div_top_of_message.appendChild(name)
    var bottom = document.createElement('div')
    bottom.className = "bottom"
    bottom.innerHTML = user_message
    div.appendChild(div_top_of_message)
    div.appendChild(bottom)
    document.getElementById("chatbot_inner").appendChild(div)

    const element = document.getElementById('chatbot_inner');
    element.lastElementChild.scrollIntoView({ behavior: 'smooth' });
    // get response from backend here
    const formdata = new FormData();
    formdata.append("message", user_message);
    places_arr = document.getElementById('hidden_element_with_data').value;
    formdata.append("data", places_arr);


    axios.post("/user/chat", formdata, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(res => {
        appendSystemMessage(res.data.response)
      })
      .catch(err => { console.error(err); })


  }

  function appendSystemMessage(response) {

    var div = document.createElement('div')
    div.className = "left_message"
    var div_top_of_message = document.createElement('div')
    div_top_of_message.className = "top_of_message"
    var img = document.createElement('img')
    img.src = "/favicon.ico"
    img.className = "img_of_person"
    var name = document.createElement('div')
    name.className = "name"
    name.innerHTML = "FamBot"
    div_top_of_message.appendChild(img)
    div_top_of_message.appendChild(name)
    var bottom = document.createElement('div')
    bottom.className = "bottom"
    bottom.innerHTML = response
    div.appendChild(div_top_of_message)
    div.appendChild(bottom)
    document.getElementById("chatbot_inner").appendChild(div)
    const element = document.getElementById('chatbot_inner');
    element.lastElementChild.scrollIntoView({ behavior: 'smooth' });
  }

  useEffect(() => {
    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );



    autoCompleteRef.current.addListener("place_changed", () => {
      const place = autoCompleteRef.current.getPlace();
      var location = null;
      places_arr = [];
      // wrap this in a try catch block
      try {
        if (!place.geometry || !place.geometry.location) {
          if (autoCompleteRef.current.gm_accessors_.place.Qs.predictions[0].fE) {
            location = autoCompleteRef.current.gm_accessors_.place.Qs.predictions[0].fE;
          }
          else {
            location = null;
            toast.error('Please enter a valid location');
          }
        }
        else {
          location = place.geometry.location;
        }

      }
      catch (err) {
        location = null;
        toast.error('Please enter a valid location');
        return;
      }

      if (location == null) {
        toast.error('Please enter a valid location');
        return;
      }

      if (document.getElementById('searchbar').classList.contains('preSearch')) {
        document.getElementById('loading').style.display = 'block';
      }

      setTimeout(() => {
        if (document.getElementById('loading').style.display == 'block') {
          document.getElementById('loading').style.display = 'none';
        }
      },
        4000
      );

      const map = new window.google.maps.Map(mapRef.current, {
        center: location,
        zoom: 15
      });

      if (markerRef.current) {
        markerRef.current.setMap(null);
      }

      markerRef.current = new window.google.maps.Marker({
        map: map,
        position: location
      });

      // Perform a Nearby Search for wedding and banquet halls
      const service = new window.google.maps.places.PlacesService(map);
      const request = {
        location: location,
        radius: '5000', // 5 kilometers
        type: ['wedding_hall', 'banquet_hall'], // Searching for wedding and banquet halls
        keyword: 'wedding hall banquet hall'
      };


      service.nearbySearch(request, (results, status) => {
        if (results.length == 0) {
          toast.error('No wedding or banquet halls found in the vicinity');
          return;
        }
        if (status === window.google.maps.places.PlacesServiceStatus.OK && results) {
          
          setPlaces(results.slice(0, 10)); // Limit to 10 results

          const detailPromises = results.slice(0, 10).map((place) => {
            const placeDetailsRequest = {
              placeId: place.place_id,
            };
            return new Promise((resolve, reject) => {
              service.getDetails(placeDetailsRequest, (details, status) => {
                if (status === window.google.maps.places.PlacesServiceStatus.OK && details) {
                  place.formatted_phone_number = details.formatted_phone_number;
                  if (details.opening_hours) {
                    place.openOrNot = details.opening_hours.isOpen();
                  }
                  else {
                    place.openOrNot = false;
                  }
                  if (details.reviews) {
                    place.reviews = details.reviews;
                  }
                  else {
                    place.reviews = [];
                  }
                  place.url = details.url;
                  places_arr.push(place);
                  resolve(); // Resolve the promise when details are fetched
                } else {
                  reject(new Error("Failed to get details for place"));
                }
              });
            });
          });

          Promise.all(detailPromises)
            .then(() => {
              createList();
            })
            .catch((error) => {
              console.error("Error fetching details:", error);
            });
        }
      });
    });
    if (chatbotinner.current) {
      chatbotinner.current.scrollTop = chatbotinner.current.scrollHeight;
    }
  }, []);

  // Scroll to bottom whenever messages update
  useEffect(() => {
    if (chatbotinner.current) {
      chatbotinner.current.scrollTop = chatbotinner.current.scrollHeight;
    }
    // if loading is active after 4 seconds disable it
    
  }, [messages]);

  return (
    <div id="outer_ai">

      <div id="left_ai" >
        <div id="searchbar" className="preSearch">
          <h2 className="hideAfter heading_hide orange">{t.dream}</h2>
          <p className="hideAfter text_hide">{t.ai_desc}</p>

          <div id="searchbar_inner" className="preSearchInner">
            <input ref={inputRef} id="search" onKeyDown={handleKeyDown} />
            <span className="clear" onClick={clearSearch} title='Clear'>&times;</span>
          </div>

        </div>
        <div id="outer_result">
          <div id="inner_result">
            <ul id="list_of_results">

            </ul>
          </div>
        </div>
      </div>
      <div id="center_ai" className="preSearchHidden">
        <div ref={mapRef} id="map"></div>
      </div>
      <div id="right_ai" className="preSearchHidden">
        <div id="chatbout_outer">
          <div id="chatbot_inner" ref={chatbotinner}>

            <div className="left_message">
              <div className="top_of_message">
                <img src="/favicon.ico" alt="" className="img_of_person" />
                <div className="name">{t.fambot}</div>
              </div>
              <div className="bottom">{t.fambot_desc} 💃🕺</div>
            </div>



          </div>
        </div>
        <div id="send_message">
          <input type="text" id="message" placeholder={`${t.query}`} onKeyDown={handleKeyDownMessage} />
          <div id="send_button" onClick={appendUserMessage}>
            <img src="/send_message.png" alt="" />
          </div>
        </div>
      </div>
      <input type="hidden" id="hidden_element_with_data" value="[]" />
      <div id="loading">
        <div className='loadingAI'>
          <i className='bx bx-loader-alt bx-spin' ></i><p>{t.loading}...</p>
        </div>
      </div>

      <div id="chat_nav" onClick={openChatPhone}>
        <img src="/chat.png" alt="" />
      </div>
      <div id="map_nav" onClick={openMapPhone}>
        <img src="/loc.png" alt="" />
      </div>

    </div>
  );
};

export default AutoComplete;

import {useEffect, useState } from "react";
import './ForgotPassword.css'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import axios from '../axios.js'
import translations from '../locales/translations.json';


function ForgotPassword() {
  const navigate = useNavigate()

  const [email, setEmail] = useState(true)
  const [form, setForm] = useState({email: ""})

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  };

  const savedLanguage = getCookie('selectedLanguage');
  const initialLanguage = savedLanguage ? savedLanguage.toUpperCase() : undefined;

  const [currentLanguage, setCurrentLanguage] = useState(initialLanguage || 'EN');
  const [t, setT] = useState(translations[currentLanguage]);

  useEffect(() => {
    // If no saved language, set English by default
    if (!savedLanguage) {
      document.cookie = `selectedLanguage=en; path=/`;
    }

    // Update translation based on the current language
    setT(translations[currentLanguage]);
  }, [currentLanguage, savedLanguage]);

  function checkInput(e, inputContent, inputControl, conditions=false) {
    if (!inputContent.trim() || conditions) {
      inputControl(false);
      return false;
    }
    inputControl(true);
    return true;
  }

  const handleForm = (e)=>{
    
    if(!checkInput(e, form.email, setEmail, !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(form.email.trim()))){
      setForm({ email: ""});
      toast.warn("Enter correct email");
      return
    }
    axios.post('/user/forgotPassword',{email:form.email})
    .then((res)=>{
        toast.success(res.data.message)
        navigate(`/otp/${form.email}?isForgotPassword=true`)

    })
    .catch(e=>{console.log("Error occured",e);toast.error('Error Occured')})
  }

  return (
    <div className="page-element pwdrecovery-content content">
      <main className="page-element form password-recovery">
        <h2 className="page-element title">{t.lost_password}</h2>
        <input type="email" placeholder={`${t.email}`} title={t.email_error} id="email-input" className={(email) ? 'page-element':'page-element error'}
          value={form.email}
          onChange={(e)=>{setForm({email:e.target.value})}}
        />
        <button className="page-element button" type="submit" id="recover-password-button" onClick={e=>handleForm(e)}>{t.send}</button>
      </main>
    </div>
  )
}

export default ForgotPassword
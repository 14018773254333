import {useEffect, useState } from "react";
import './Login.css'
import {Link ,useNavigate} from 'react-router-dom'
import { toast } from 'react-toastify';
import axios from '../axios'
import translations from '../locales/translations.json';

function Login() {

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  };

  const savedLanguage = getCookie('selectedLanguage');
  const initialLanguage = savedLanguage ? savedLanguage.toUpperCase() : undefined;

  const [currentLanguage, setCurrentLanguage] = useState(initialLanguage || 'EN');
  const [t, setT] = useState(translations[currentLanguage]);

  useEffect(() => {
    // If no saved language, set English by default
    if (!savedLanguage) {
      document.cookie = `selectedLanguage=en; path=/`;
    }

    // Update translation based on the current language
    setT(translations[currentLanguage]);
  }, [currentLanguage, savedLanguage]);



  const [email, setEmail] = useState(true)
  const [password, setPassword] = useState(true)
  const [form, setForm] = useState({email:"",password:""})

  const navigate = useNavigate()

  async function handleLoginForm(e) {
    const formData = {
      email: form.email,
      password: form.password
    }

    axios.post('/user/login',formData)
      .then(function(response) {
        console.log('Request successful');
        const user = response.data.user
        setForm({email:"",password:""});
        localStorage.setItem('token',user.token_value)
        localStorage.setItem('email',user.email)
        navigate(`/u/${user.first_name}_${user.last_name}/profile`)
      })
      .catch(function(error) {
        console.error('Request failed:', error);
        const error_msg = error.response.data.error
        toast.error("Incorrect Email ID or Password")
        if(error_msg==="email is not verified"){
          toast.warn("OTP sent to your mail")
          navigate(`/otp/${form.email}`)
        }
      });
  }

  useEffect(() => {
    if(localStorage.getItem('demo_user')) {
      navigate('/u/demo_user/home');
    }
    else{
    const userData = localStorage.getItem('token')

    if(userData){
      axios.post('/user/login',{token_value:userData})
      .then(({data})=>{
        const user = data.user
        localStorage.setItem('email',user.email)
        navigate(`/u/${user.first_name}_${user.last_name}/profile`)
      })
      .catch(()=>{
        localStorage.clear()
      })
    }}
  }, [navigate])
  

  return (
    <div className="page-element content">
        <main className="page-element form login">
            <h2 className="page-element title dark-accent-text">{t.login_button}</h2>
            <input 
              type="email" 
              placeholder={`${t.email}`} 
              title="Email address matching your account goes here." 
              id="login-email-input" 
              className={`page-element ${(email) ? '':'error'}`} 
              value={form.email}
              onChange={(e)=>{setForm({...form,email:e.target.value})}} 
            />
            <input 
              type="password" 
              placeholder={`${t.password}`}
              title="Password matching your account goes here." 
              id="login-password-input" 
              className={`page-element ${(password) ? '':'error'}`} 
              value={form.password}
              onChange={(e)=>{setForm({...form,password:e.target.value})}} 
            />
            <button className="page-element button" type="submit" id="login-button" onClick={handleLoginForm}>{t.login}</button>
            <Link to="/password-recovery" className="page-element link" id="lost-password-link">{t.lost_password}</Link>
            <Link to="/registration" className="page-element link" id="create-account-link">{t.create_account}</Link>
        </main>
    </div>
  )
}

export default Login
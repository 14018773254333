import React, { useState, useEffect } from 'react';
import './HomeNav.css';
import translations from '../locales/translations.json';
import LanguageSelector from './LanguageSelector';

function HomeNav() {
  // Function to get cookie value by name
  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  };

  // Check if the language cookie exists; if not, default to undefined (will be handled in useEffect)
  const savedLanguage = getCookie('selectedLanguage');
  const initialLanguage = savedLanguage ? savedLanguage.toUpperCase() : undefined;

  const [currentLanguage, setCurrentLanguage] = useState(initialLanguage || 'EN');
  const [t, setT] = useState(translations[currentLanguage]);

  useEffect(() => {
    // If no saved language, set English by default
    if (!savedLanguage) {
      document.cookie = `selectedLanguage=en; path=/`;
    }

    // Update the translation based on the selected language
    setT(translations[currentLanguage]);

    // Set the cookie based on the selected language
    document.cookie = `selectedLanguage=${currentLanguage.toLowerCase()}; path=/`;
  }, [currentLanguage, savedLanguage]);

  return (
    <header className="page-element" id="pageNavigation">
      <a className="page-element" id="home-link" href="/">
        <img src="/logo.png" className="page-element" alt="FamSketch" />
      </a>
      <nav className="page-element pages dark-accent-text">
        <a href="/download" className="page-element link">{t.download}</a>
        <a href="/about" className="page-element link">{t.about}</a>
        <a href="/FAQ" className="page-element link">{t.faq}</a>
      </nav>
      <LanguageSelector currentLanguage={currentLanguage} setCurrentLanguage={setCurrentLanguage} />
    </header>
  );
}

export default HomeNav;

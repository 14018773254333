import React from 'react'
import './UserProfile.css'


function UserProfile({setShowUserProfile,userProfileData}) {
  const user = userProfileData.user
  const posts = userProfileData.posts
  return ( user && posts &&
    <section className='app-element user-profile' id='modal'>
      <div className='app-element center'>
        <button className='app-element close-user-profile' onClick={()=>setShowUserProfile(false)}><i className='bx bx-plus rotated'></i></button>
        <div className='app-element profile-pannel'>
          <img className={`app-element profile-picture ${!user.image && 'default-profile-picture'}`} src={user.image?`${user.image}`:'/profilePicture-default.png'}/>
          <div className='app-element profile-detail-pannel'>
            <p className='app-element name'>{`${user.first_name} ${user.last_name}`}</p>
            <p className='app-element username'>{`@${user.username}`}</p>
            <p className='app-element email'>{`${user.email}`}</p>
            <p className='app-element born'>{`Born: ${user.date_of_birth ? user.date_of_birth : "DD.MM.YYYY"}`}</p>
            <p className='app-element relation'>{`Status: ${user.marital_status?user.marital_status:"Single"}`}</p>
          </div>
        </div>
        <div className='app-element posts-pannel invisible-scrollbar' id='post'>{user.public?posts.length>0? posts.map((post,index)=>{return (
          <article className='app-element message' key={index}>
            <header className='app-element info'>
              <img src='/profilePicture-default.png' className='app-element profile-picture default-profile-picture'/>
              <p className='app-element author'>{post.username}</p>
              <p className='app-element time'>{post.created_at}</p>
            </header>
            <div className='app-element message-content'>
              {post.text && <p>{post.text}</p>}
              {post.photo && <img src={post.photo} style={{width:'100%',height:'100%'}} className='post-image'/> }
              {post.audio && <audio src={post.audio} controls='true' />}
              {post.video && <video src={post.video} controls='true' />}
            </div>
            <div className='app-element like-comment'>
              <div className='app-element like-comment-button'>
                <button>{post.liked?<i class='bx bxs-like' ></i>:<i class='bx bx-like'></i>}</button>
                <button><i class='bx bx-comment'></i></button>
              </div>
              <p className='like-detail'>{`${post.total_likes} likes`}</p>
              <p className='comment-detail'>{post.total_comments>0?`${post.total_comments} comments`: `Be the First one to comment`}</p>
            </div>
          </article>
        )}):"No Post":user.connection==="accepted"?'No Post':"Private Account"}</div>
      </div>
    </section>
  )
}

export default UserProfile
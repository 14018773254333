import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import translations from '../../../../locales/translations.json';

function UserProfile({followers,user}) {

  const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  };
  const savedLanguage = getCookie('selectedLanguage');
  const initialLanguage = savedLanguage ? savedLanguage.toUpperCase() : undefined;

  const [currentLanguage, setCurrentLanguage] = useState(initialLanguage || 'EN');
  const [t, setT] = useState(translations[currentLanguage]);

  useEffect(() => {
    // If no saved language, set English by default
    if (!savedLanguage) {
      document.cookie = `selectedLanguage=en; path=/`;
    }

    // Update translation based on the current language
    setT(translations[currentLanguage]);
  }, [currentLanguage, savedLanguage]);

  
  const handleDropdown=()=>{}
  const handleDropdownElement=()=>{}
  return (
    <section className="app-element user invisible-scrollbar">
        {/* <img src="/logo.png" className="app-element logo" alt="FamSketch" /> */}
        <img alt="profilePicture-default" src={user.image?`${user.image}`:"/profilePicture-default.png"} className={`app-element profile-picture ${!user.image && 'default-profile-picture'}`}
          id="panel-search-profile-picture" />
        <h2 className="app-element name">{`${user.username}`}</h2>
        <div className="app-element social">
          <a href='#ffr' className="app-element people icon-text-wrapper">
            <i className="app-element bx bxs-group"></i>
            <span id="number">{followers.length}</span>
          </a>
          <Link to="{ name: 'user-id-calendar', params: userData }" className="app-element link">
            <i className="app-element bx bx-calendar"></i>
          </Link>
          <a href="" className="app-element function">
            <i className="app-element bx bx-heart"></i>
          </a>
          <a href="" className="app-element function">
            <i className="app-element bx bx-share-alt"></i>
          </a>
          <button className="app-element dropdown">
            <i onClick={handleDropdown} className="app-element bx bxs-down-arrow"></i>
            <div className="app-element list hidden">
              <a onClick={handleDropdownElement} className="app-element link element">
                <i className="app-element bx bxl-instagram-alt"></i>
              </a>
              <a onClick={handleDropdownElement} className="app-element link element">
                <i className="app-element bx bxl-facebook-square"></i>
              </a>
              <a onClick={handleDropdownElement} className="app-element link element">
                <i className="app-element bx bxl-twitter"></i>
              </a>
              <a onClick={handleDropdownElement} className="app-element link element">
                <i className="app-element bx bxl-linkedin-square"></i>
              </a>
              <a onClick={handleDropdownElement} className="app-element link element">
                <i className="app-element bx bxl-youtube"></i>
              </a>
            </div>
          </button>
        </div>
        <div className="app-element info">
          <p className="app-element birth info"><span className="app-element tag">{t.born}:</span> {user.date_of_birth?user.date_of_birth:"YYYY-MM-DD"}</p>
          <p className="app-element location info"><span className="app-element tag">{t.city}:</span> {user.city?user.city:"City"}</p>
          <p className="app-element relationship-status info"><span className="app-element tag">{t.status}:</span> {user.marital_status?user.marital_status:"Single"}</p>
        </div>

        <p className="app-element quote">{user.status?user.status:'We are memories for our loved ones.'}</p>
    </section>
  )
}

export default UserProfile
import {useEffect, useState } from "react";
import './Download.css'
import translations from '../locales/translations.json';

function Download() {
    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
        return null;
      };
    
      const savedLanguage = getCookie('selectedLanguage');
      const initialLanguage = savedLanguage ? savedLanguage.toUpperCase() : undefined;
    
      const [currentLanguage, setCurrentLanguage] = useState(initialLanguage || 'EN');
      const [t, setT] = useState(translations[currentLanguage]);
    
      useEffect(() => {
        // If no saved language, set English by default
        if (!savedLanguage) {
          document.cookie = `selectedLanguage=en; path=/`;
        }
    
        // Update translation based on the current language
        setT(translations[currentLanguage]);
      }, [currentLanguage, savedLanguage]);
    
  return (
    <div className="page-element content">
        <section className="page-element section right-main" id="landing">
            <header className="page-element speech dark-accent-text">
                <h1 className="page-element section-title">{t.download}</h1>
                <h3 className="page-element section-subtitle">
                {t.subtitle}
                </h3>
            </header>
            <img alt="family" src="/family.png" className="page-element" />
            <div className="page-element main options">
                <div className="page-element option" id="android-brief">
                    <h2 className="page-element option-title dark-accent-text">{t.android}</h2>
                    <div className="page-element buttons">
                        <a href="/" target="_blank" className="page-element download-link link button">{t.download}</a>
                        <a href="#Android" className="page-element more-link link button">{t.ios.learnMore}</a>
                    </div>
                </div>
                <div className="page-element option" id="ios-brief">
                    <h2 className="page-element option-title dark-accent-text">{t.ios.title}</h2>
                    <div className="page-element buttons">
                        <a href="/" target="_blank" className="page-element download-link link button">{t.download}</a>
                        <a href="#iOS" className="page-element more-link link button">{t.ios.learnMore}</a>
                    </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default Download
import React, { useState, useEffect } from 'react';
import './Memorial.css';
import { Link, generatePath, useParams } from 'react-router-dom';
import translations from '../../../locales/translations.json';

function Memorial() {
  const [displayName, setDisplayName] = useState(true);
  const params = useParams();

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  };
  
  const savedLanguage = getCookie('selectedLanguage');
  const initialLanguage = savedLanguage ? savedLanguage.toUpperCase() : undefined;

  const [currentLanguage, setCurrentLanguage] = useState(initialLanguage || 'EN');
  const [t, setT] = useState(translations[currentLanguage]);

  useEffect(() => {
    if (!savedLanguage) {
      document.cookie = `selectedLanguage=en; path=/`;
    }
    setT(translations[currentLanguage]);
  }, [currentLanguage, savedLanguage]);

  const AudioPlayer = ({ title, src }) => (
    <div className="wedding audio-player border rounded-lg p-3 mb-4 shadow-sm">
      <h4 className="text-sm font-medium mb-2 width_265">{title}</h4>
      <audio
        className="w-full"
        controls
        src={src}
      >
        Your browser does not support the audio element.
      </audio>
    </div>
  );

  return (
    <div className='app-element memorial-content content'>
      <div className='app-element memorial'>
        <div className='app-element datewise invisible-scrollbar'>
          <div className='date'>
            <button id='prev'>{'<'}</button>
            <p id='date'>{"Day 20, March 2021"}</p>
            <button id='next'>{'>'}</button>
          </div>
          <img className='thumbnail' alt='event-image' src={currentLanguage === 'EN' ? '/memorial3.png' : '/memorial3.png'} />
          <div className='date-note'>
            <textarea className="app-element plan-note invisible-scrollbar" id="content" placeholder="Add notes" />
          </div>
        </div>
        <div className='app-element memorial-data invisible-scrollbar'>
          <div className='app-element memorial-title'>{t.our_wedding}</div>
          <div className='app-element memorial-body invisible-scrollbar'>
            <p>
              {t.memorial_desc_1}
            </p>
            <div id="image_1">
              <img className='wedding' alt='event-image' id='img1' src={currentLanguage === 'EN' ? '/memorial2.png' : '/memorial2.png'} />
              <img className='wedding' alt='event-image' id='img2' src={currentLanguage === 'EN' ? '/memorial1.png' : '/memorial1.png'} />
            </div>
            <p>
              {t.memorial_desc_2}
            </p>
            <div className="app-element audio-player-container">
            <div id="audio-container">
              <AudioPlayer title="Wedding Ceremony"  src="/api/placeholder/audio1" />
              <AudioPlayer title="First Dance" src="/api/placeholder/audio2" />
              <AudioPlayer title="Reception Highlights" src="/api/placeholder/audio3" />
            </div>
            <div id="image-container">
            <img className='wedding' alt='event-image' id='img4' src={currentLanguage === 'EN' ? '/memorial4.png' : '/memorial4.png'} />
            </div>
            </div>
          </div>
        </div>
      </div>
      <nav className="app-element" id="sidebar">
        <button onClick={(e)=>{setDisplayName(!displayName)}} className="app-element toggle" id="toggle-pannel-left">
          <i className="app-element bx bxs-right-arrow" />
        </button>
        <Link to={generatePath("/u/:user/album",params)} className="app-element icon-text-wrapper link">
          <i className="app-element bx bxs-image"></i>
          <p className={`app-element title ${displayName?"hidden":"shown"} toggled`}>{t.photos}</p>
        </Link>
        <Link to={generatePath("/u/:user/album",params)} className="app-element icon-text-wrapper link">
          <i className="app-element bx bxs-music"></i>
          <p className={`app-element title ${displayName?"hidden":"shown"} toggled`}>{t.audio}</p>
        </Link>
        <Link to={generatePath("/u/:user/album",params)} className="app-element icon-text-wrapper link">
          <i className="app-element bx bxs-video"></i>
          <p className={`app-element title ${displayName?"hidden":"shown"} toggled`}>{t.video}</p>
        </Link>
        <Link to={generatePath("/u/:user/chronicle",params)} className="app-element icon-text-wrapper link">
          <i className="app-element bx bxs-book"></i>
          <p className={`app-element title ${displayName?"hidden":"shown"} toggled`}>{t.chronicle}</p>
        </Link>
        <Link to={generatePath("/u/:user/memorial",params)} className="app-element icon-text-wrapper link">
          <i className="app-element bx bxs-book-bookmark"></i>
          <p className={`app-element title ${displayName?"hidden":"shown"} toggled`}>{t.memorial}</p>
        </Link>
      </nav>
    </div>
  );
}

export default Memorial;
import React, { useState, useEffect } from 'react';
import './HomeFooter.css';
import { useDemoData } from '../composables/demo';
import translations from '../locales/translations.json';

function HomeFooter() {
  const data = useDemoData();

  // Function to get cookie value by name
  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  };

  const savedLanguage = getCookie('selectedLanguage');
  const initialLanguage = savedLanguage ? savedLanguage.toUpperCase() : undefined;

  const [currentLanguage, setCurrentLanguage] = useState(initialLanguage || 'EN');
  const [t, setT] = useState(translations[currentLanguage]);

  useEffect(() => {
    // If no saved language, set English by default
    if (!savedLanguage) {
      document.cookie = `selectedLanguage=en; path=/`;
    }

    // Update translation based on the current language
    setT(translations[currentLanguage]);
  }, [currentLanguage, savedLanguage]);

  return (
    <footer className="page-element" id="pageInformations">
      <div className="page-element pages container">
        <h2 className="page-element container-title">{t.company}</h2>
        <a href="/about" className="page-element link">{t.about}</a>
        <a href="/#How-it-works" className="page-element link">{t.howItWorks}</a>
        <a href="/FAQ" className="page-element link">{t.faq}</a>
        {/* <a href="/" className="page-element link">{t.blog}</a> */}
        {/* <a href={`/u/${data.userData.params.user}/home`} target="_blank" rel="noreferrer" className="page-element link">
          {t.demoAccount}
        </a> */}
      </div>
      <div className="page-element download container">
        <h2 className="page-element container-title">{t.download}</h2>
        <a href="/download#Android" className="page-element link">{t.android}</a>
        <a href="/download#iOS" className="page-element link">{t.ios.title}</a>
      </div>
      <div className="page-element company container">
        <h2 className="page-element container-title">{t.company}</h2>
        {/* <a href="/about" className="page-element link">{t.about}</a> */}
        {/* <a href="/#careers" className="page-element link">{t.careers}</a> */}
        <a href="/Terms_and_Conditions" className="page-element link">{t.termsConditions}</a>
        <a href="/Privacy_policy" className="page-element link">{t.privacyPolicy}</a>
        <a href="/Ads_Policy" className="page-element link">{t.adsPolicyTitle}</a>
      </div>
      
      <p className="page-element watermark">© 2024 FamSketch</p>
    </footer>
  );
}

export default HomeFooter;

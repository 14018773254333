import React, { useState, useEffect } from 'react';
import './FAQ.css';
import './Ads_policy.css';
import { useFAQData } from '../composables/FAQ-data';
import translations from '../locales/translations.json';

function ADS() {
  const data = useFAQData();
  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  };
  const savedLanguage = getCookie('selectedLanguage');
  const initialLanguage = savedLanguage ? savedLanguage.toUpperCase() : 'EN';

  const [currentLanguage, setCurrentLanguage] = useState(initialLanguage);
  const [t, setT] = useState(translations[currentLanguage]);

  useEffect(() => {
    if (!savedLanguage) {
      document.cookie = `selectedLanguage=en; path=/`;
    }
    setT(translations[currentLanguage]);
  }, [currentLanguage, savedLanguage]);

  return (
    <div className="page-element ads">
      <h1>{t.adsPolicyTitle || 'Ads Policy'}</h1>
      <p>{t.ads_policy}</p>
    </div>
  );
}

export default ADS;

import React from 'react'
import './Search.css'
import Sidebar from './SideBar'
import SearchProfile from './SearchProfile'
import UserProfile from './UserProfile'

function Search({sendRequest,acceptRequest,deleteRequest,unFollow,deleteFollower,searchResult,userData,showProfileModel,followers,followings,requests}) {

  return (
    <div className='content app-element search-profile invisible-scrollbar'>
      <UserProfile user={userData.user} followers={followers}/>
      <SearchProfile sendRequest={sendRequest} deleteRequest={deleteRequest} unFollow={unFollow} searchResult={searchResult} showProfileModel={showProfileModel}/>
      <Sidebar acceptRequest={acceptRequest} deleteRequest={deleteRequest} unFollow={unFollow} deleteFollower={deleteFollower} showProfileModel={showProfileModel} followers={followers} followings={followings} requests={requests}/>
    </div>
  )
}

export default Search
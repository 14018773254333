import React, { useEffect, useState } from 'react'
import './Index.css'
import { Link, generatePath, useParams } from 'react-router-dom'
import translations from '../../../locales/translations.json';

function Index() {
    const params = useParams();
    const [displayName, setDisplayName] = useState(false)

    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
        return null;
      };
      const savedLanguage = getCookie('selectedLanguage');
      const initialLanguage = savedLanguage ? savedLanguage.toUpperCase() : undefined;
    
      const [currentLanguage, setCurrentLanguage] = useState(initialLanguage || 'EN');
      const [t, setT] = useState(translations[currentLanguage]);
    
      useEffect(() => {
        // If no saved language, set English by default
        if (!savedLanguage) {
          document.cookie = `selectedLanguage=en; path=/`;
        }
    
        // Update translation based on the current language
        setT(translations[currentLanguage]);
      }, [currentLanguage, savedLanguage]);
    

  return (
    <div className="app-element Index_content content">
        <main className="app-element familytree">
        <div className="app-element member">
            <img alt='profilePicture-default' src="/profilePicture-default.png" className="app-element profile-picture default-profile-picture" />
            <p className="app-element name">{t.name_surname}</p>
            <p className="app-element title">{t.grandpa}</p>
            <p className="app-element birth info"><span className="app-element tag">{t.born}:</span> DD.MM.YYYY</p>
            <p className="app-element location info"><span className="app-element tag">{t.city}:</span> Košice</p>
            <p className="app-element relationship-status info"><span className="app-element tag">{t.status}:</span>{t.single}</p>
        </div>
        </main>
        <nav className="app-element" id="sidebar">
            <button onClick={(e)=>{setDisplayName(!displayName)}} className="app-element toggle" id="toggle-pannel-left">
                <i className="app-element bx bxs-right-arrow" />
            </button>
            <Link to={generatePath("/u/:user/album",params)} className="app-element icon-text-wrapper link">
                <i className="app-element bx bxs-image"></i>
                <p className={`app-element title ${displayName?"hidden":"shown"} toggled`}>{t.photos}</p>
            </Link>
            <Link to={generatePath("/u/:user/album",params)} className="app-element icon-text-wrapper link">
                <i className="app-element bx bxs-music"></i>
                <p className={`app-element title ${displayName?"hidden":"shown"} toggled`}>{t.audio}</p>
            </Link>
            <Link to={generatePath("/u/:user/album",params)} className="app-element icon-text-wrapper link">
                <i className="app-element bx bxs-video"></i>
                <p className={`app-element title ${displayName?"hidden":"shown"} toggled`}>{t.video}</p>
            </Link>
            <Link to={generatePath("/u/:user/chronicle",params)} className="app-element icon-text-wrapper link">
                <i className="app-element bx bxs-book"></i>
                <p className={`app-element title ${displayName?"hidden":"shown"} toggled`}>{t.chronicle}</p>
            </Link>
            <Link to={generatePath("/u/:user/memorial",params)} className="app-element icon-text-wrapper link">
                <i className="app-element bx bxs-book-bookmark"></i>
                <p className={`app-element title ${displayName?"hidden":"shown"} toggled`}>{t.memorial}</p>
            </Link>
        </nav>
    </div>
  )
}

export default Index
import React, { useEffect, useState } from 'react'
import './Post.css'
import axios from '../../../../axios'
import { toast } from 'react-toastify'

function Post({deleteComment,setShowPost,postData,postID,postBool,likePost,unLikePost,user,postComment}) {

  // const [postData, setPostData] = useState({})
  const token = localStorage.getItem('token')
  const [comments, setComments] = useState([])
  const [showComments, setShowComments] = useState(false)
  const [likes, setLikes] = useState([])
  const [showLikes, setShowLikes] = useState(false)
  const [cmntForm, setCmntForm] = useState(false)
  const [comment, setComment] = useState('')

  const [replies, setReplies] = useState([])
  const [showReply, setShowReply] = useState(null)
  const [showReplyForm, setShowReplyForm] = useState(null)
  const [reply, setReply] = useState('')

  const deleteCmnt = (interactionID)=>{
    const alert = window.confirm("Do You want to delete the comment?")
    if(alert){
      axios.post('/user/deleteComment',{token:token,post:postID,interaction_public_id:interactionID})
      .then(res=>{
        console.log(res.data)
        setComments(comments.filter(cmnt=>cmnt.interaction_public_id!==interactionID))
        deleteComment(postID)
      })
      .catch(e=>console.error(e))
    }
  }

  const getComments =()=>{
    axios.post('/user/getComments',{token:token,post:postID})
    .then(res=>{setComments(res.data.interaction_data);console.log(res.data.interaction_data)})
    .catch(e=>console.error(e))
  }

  const getLikes =()=>{
    axios.post('/user/getLikes',{post:postID})
    .then(res=>{setLikes(res.data.interaction_data)})
    .catch(e=>console.error(e))
  }

  const likeComment = (interactionID)=>{
    axios.post('/user/likeComment',{token:token,interaction_public_id:interactionID})
    .then(res=>{
      console.log(res.data)
      const cmnts = comments.map(comment=>{
        if(comment.interaction_public_id===interactionID){
          comment.liked=true
          return comment
        }else{
          return comment
        }
      })
      setComments(cmnts)
    })
    .catch(e=>console.error(e))
  }

  const unLikeComment = (interactionID)=>{
    axios.post('/user/unlikeComment',{token:token,interaction_public_id:interactionID})
    .then(res=>{
      console.log(res.data)
      const cmnts = comments.map(comment=>{
        if(comment.interaction_public_id===interactionID){
          comment.liked=false
          return comment
        }else{
          return comment
        }
      })
      setComments(cmnts)
    })
    .catch(e=>console.error(e))
  }

  const getReplies = (interactionID)=>{
    axios.post('/user/getReplies',{interaction_public_id:interactionID})
    .then(res=>{
      setReplies(res.data.interaction)
      setShowReply(interactionID)
    })
    .catch(e=>console.error(e))
  }

  const postReply = (interactionID)=>{
    if(reply.length===0){
      toast.warning("Cannot reply without content.")
      return
    }
    axios.post('/user/replyToComment',{token:token,interaction_public_id:interactionID,reply:reply})
    .then(res=>{
      setReplies(res.data.interaction)
      setShowReply(interactionID)
      setReply('')
    })
    .catch(e=>console.error(e))
  }


  useEffect(() => {
    getComments()
    getLikes()
    setCmntForm(postBool.cmntForm)
    setShowComments(postBool.showComments)
    setShowLikes(postBool.showLikes)
    
  }, [])
  

  return (
    <div className='app-element post-content' id='modal'>
        {showLikes && <section className='app-element likes invisible-scrollbar'>
          <button className='app-element close-likes' onClick={()=>setShowLikes(false)}><i className='bx bx-plus rotated-45'></i></button>
            {likes.map((like,index)=>{
              return <div className='app-element liked-user' key={index}>
                <p>{`Name: ${like.name}`}</p>
                <p>{`Email: ${like.email}`}</p>
              </div>
            })}
        </section>}
        <section className='app-element post invisible-scrollbar' id='post'>
            <article className='app-element message'>
              <button className='app-element close-post' onClick={()=>setShowPost(false)}><i className='bx bx-plus rotated-45'></i></button>
              <header className='app-element info'>
                <img src='/profilePicture-default.png' className='app-element profile-picture default-profile-picture'/>
                <p className='app-element author'>{postData.username}</p>
                <p className='app-element time'>{postData.created_at}</p>
              </header>
              <div className='app-element message-content'>
                {postData.text && <p>{postData.text}</p>}
                {postData.photo && <img src={postData.photo} style={{width:'100%',height:'100%'}} className='post-image'/> }
                {postData.audio && <audio src={postData.audio} controls='true' />}
                {postData.video && <video src={postData.video} controls='true' />}
              </div>
              <div className='app-element like-comment'>
                <div className='app-element like-comment-button'>
                  {postData.liked?<button onClick={()=>{unLikePost(postData.public_id,likes,setLikes)}}><i className='bx bxs-like' ></i></button>:<button onClick={()=>{likePost(postData.public_id,likes,setLikes)}}><i className='bx bx-like'></i></button>}
                  <button onClick={()=>setCmntForm(true)}><i className='bx bx-comment'></i></button>
                </div>
                <p className='like-detail' onClick={()=>setShowLikes(true)}>{`${postData.total_likes} likes`}</p>
                <p className='comment-detail' onClick={()=>setShowComments(true)}>{postData.total_comments>0?`View all ${postData.total_comments} comments`: `Be the First one to comment`}</p>
              </div>
              {cmntForm && <form onSubmit={(e)=>{e.preventDefault()}}>
                <input value={comment} onChange={(e)=>setComment(e.target.value)} placeholder='Write your comment' className='comment-input'/>
                <button onClick={()=>{postComment(postID,comment,comments,setComments); setComment('')}}><i className='bx bxs-send' ></i></button>
              </form>}
            </article>
        </section>
        {showComments && <section className='app-element comments invisible-scrollbar'>
          <button className='app-element close-comment' onClick={()=>setShowComments(false)}><i className='bx bx-plus rotated-45'></i></button>
            {comments.map((cmnt,index)=>{
              return <div className='comment-content' key={index}>
                {(user.username===postData.username || user.username===cmnt.username) && <button className='delete-comment' onClick={()=>deleteCmnt(cmnt.interaction_public_id)}><i className='bx bx-plus rotated-45'></i></button>}
                <div className='comment-details'>
                  <p className='name'>{cmnt.name}</p>
                  <p className='time'>{cmnt.created_at}</p>
                  <p className='email'>{cmnt.email}</p>
                </div>
                <div className='comment invisible-scrollbar'>
                  <p>{cmnt.comment}</p>
                  {cmnt.liked?<i className='bx bxs-heart' onClick={()=>unLikeComment(cmnt.interaction_public_id)}></i>:<i className='bx bx-heart' onClick={()=>likeComment(cmnt.interaction_public_id)}></i>}
                </div>
                <div className='reply-content'>
                  <div className='reply-buttons'>
                    <p onClick={()=>{
                      if(showReplyForm!==cmnt.interaction_public_id)
                      setReply("@"+cmnt.username+" ")
                      else{
                        if(reply.length===0)
                        setReply("@"+cmnt.username+" ")
                        else
                        setReply(reply+" @"+cmnt.username+" ")
                      }
                      setShowReplyForm(cmnt.interaction_public_id)

                    }}>Reply</p>
                    <p onClick={()=>{
                      if(showReply!==cmnt.interaction_public_id){
                        getReplies(cmnt.interaction_public_id)
                      }
                      else setShowReply(null)
                    }}>{showReply===cmnt.interaction_public_id? 'Close replies': `View all replies`}</p>
                  </div>
                  {showReplyForm===cmnt.interaction_public_id && <form onSubmit={(e)=>e.preventDefault()}>
                    <input value={reply} onChange={e=>setReply(e.target.value)} placeholder='Write your reply' className='reply-input' autoFocus />
                    <button onClick={()=>{postReply(cmnt.interaction_public_id)}}><i className='bx bxs-send' ></i></button>
                  </form> }
                  {showReply===cmnt.interaction_public_id && <div className='replies invisible-scrollbar'>{
                    replies.length>0?
                    replies.map((user,index)=>{
                      const reply=user.user
                      return <div className='reply' key={index}>
                        <header>{reply.username}</header>
                        <p>{reply.reply}</p>
                      </div>
                    }):<div className='reply'>No reply</div>
                  }</div>}
                </div>
              </div>
            })}
        </section>}
    </div>
  )
}

export default Post
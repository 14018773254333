import React, { useEffect, useState } from 'react'
import './Profile.css'
import { Link } from "react-router-dom";
import axios from '../../../axios.js'
import { toast } from 'react-toastify';
import translations from '../../../locales/translations.json';



function Profile({ setFeeds, userData, showPostModel, likePost, unLikePost, followersCount, loadFeeds, feeds }) {

  const user = userData.user
  const token = localStorage.getItem('token')
  const [upload, setUpload] = useState(false)
  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  };

  const savedLanguage = getCookie('selectedLanguage');
  const initialLanguage = savedLanguage ? savedLanguage.toUpperCase() : undefined;

  const [currentLanguage, setCurrentLanguage] = useState(initialLanguage || 'EN');
  const [t, setT] = useState(translations[currentLanguage]);

  useEffect(() => {
    // If no saved language, set English by default
    if (!savedLanguage) {
      document.cookie = `selectedLanguage=en; path=/`;
    }

    // Update translation based on the current language
    setT(translations[currentLanguage]);
  }, [currentLanguage, savedLanguage]);

  const renderPosts = (feeds) => {

    return (feeds.length > 0 ?
      feeds.map((post, index) => {

        return (
          <article className='app-element message ' key={index} id={post.public_id}>
            <header className='app-element info '>
              <img src={post.user.image ? post.user.image : '/profilePicture-default.png'} className='app-element profile-picture default-profile-picture' />
              <p className='app-element author'>{post.user.username}</p>
              <p className='app-element time'>{post.created_at}</p>
             
            </header>
            <div className='app-element message-content' onClick={() => { showPostModel(post, post.public_id) }}>
              {post.text && <p>{post.text}</p>}
              {post.photo && <img src={post.photo} style={{ width: '100%', height: '100%' }} className='post-image' />}
              {post.audio && <audio src={post.audio} controls='true' />}
              {post.video && <video src={post.video} controls='true' />}
            </div>
            <div className='app-element like-comment'>
              <div className='app-element like-comment-button'>
                {post.liked ? <button onClick={() => { unLikePost(post.public_id) }}><i className='bx bxs-like' ></i></button> : <button onClick={() => { likePost(post.public_id) }}><i className='bx bx-like'></i></button>}
                <button onClick={() => { showPostModel(post, post.public_id, true) }}><i className='bx bx-comment'></i></button>
              </div>
              <p className='like-detail' onClick={() => { showPostModel(post, post.public_id, false, true) }}>{`${post.total_likes} ${t.likes}`}</p>
              <p className='comment-detail' onClick={() => { showPostModel(post, post.public_id, false, false, true) }}>{post.total_comments > 0 ? `${post.total_comments} ${t.comments}` : t.comment}</p>
            </div>

          </article>
        )
      }) :
      <article className='app-element message'>{t.no_post}</article>
    )
  }


  function handleDropdownElement(e) {
    return;
  }

  function handleDropdown(e) {
    let list = e.target.parentElement.querySelector(".list")
    if (list.classList.contains("hidden")) {
      list.classList.remove("hidden")
    } else {
      list.classList.add("hidden")
    }
  }

  function openFileUpload() {
    const fileInput = document.getElementById('image-preview');
    fileInput.click();
  }

  function openAudioUpload() {
    const fileInput = document.getElementById('audio-preview');
    fileInput.click();
  }

  function openVideoUpload() {
    const fileInput = document.getElementById('video-preview');
    fileInput.click();
  }

  function handleImageUpload(event) {
    const file = event.target.files[0];
    // Your logic to handle the uploaded image here
    if (file) {
      // Example: Display a preview of the uploaded image
      document.getElementById('image-preview-name').innerText = 'Image: ' + file.name
      const reader = new FileReader();
      reader.onload = function (e) {
        const preview = document.createElement('img');
        preview.src = e.target.result;
        // Assuming you have an element with ID 'image-preview' to display the uploaded image
        document.getElementById('image-preview').appendChild(preview);
      };
      reader.readAsDataURL(file);
    }
  }

  function handleAudioUpload(event) {
    const file = event.target.files[0];
    // Check if the uploaded file is an audio file (e.g., MP3, WAV, etc.)
    if (file && file.type.startsWith('audio/')) {
      document.getElementById('audio-preview-name').innerText = 'Audio: ' + file.name
      // Example: Display a preview of the uploaded audio (e.g., show audio player)
      const audioPlayer = document.createElement('audio');
      audioPlayer.controls = true;
      const source = document.createElement('source');
      source.src = URL.createObjectURL(file);
      audioPlayer.appendChild(source);
      // Assuming you have an element with ID 'audio-preview' to display the uploaded audio
      document.getElementById('audio-preview').appendChild(audioPlayer);
    } else {
      // Handle case where the uploaded file is not an audio file
      console.error('Please upload a valid audio file.');
    }
  }

  function handleVideoUpload(event) {
    const file = event.target.files[0];
    // Check if the uploaded file is a video file (e.g., MP4, MOV, etc.)
    if (file && file.type.startsWith('video/')) {
      document.getElementById('video-preview-name').innerText = 'Video: ' + file.name
      // Example: Display a preview of the uploaded video (e.g., show video player)
      const videoPlayer = document.createElement('video');
      videoPlayer.controls = true;
      const source = document.createElement('source');
      source.src = URL.createObjectURL(file);
      videoPlayer.appendChild(source);
      // Assuming you have an element with ID 'video-preview' to display the uploaded video
      document.getElementById('video-preview').appendChild(videoPlayer);
    } else {
      // Handle case where the uploaded file is not a video file
      console.error('Please upload a valid video file.');
    }
  }

  async function sharePost() {
    setUpload(true)
    var current_url = window.location.href;
    var demo_account = false
    if (current_url.includes("demo_account")) {
      demo_account = true
    }
    //get the audio, video, text, image and print it to console
    let content = document.querySelector('#content').value;

    const fileInput = document.getElementById('image-preview');
    const fileAudio = document.getElementById('audio-preview');
    const fileVideo = document.getElementById('video-preview');

    const formData = new FormData();
    formData.append('text', content);
    formData.append('demo_user', demo_account);
    formData.append('token', token);

    if (content === "" && fileInput.files.length === 0 && fileAudio.files.length === 0 && fileVideo.files.length === 0) {
      toast.warn(t.content_upload)
      setUpload(false)
      return
    }

    if (fileInput.files.length > 0) {
      const imageFile = fileInput.files[0];
      formData.append('photo', imageFile);
    }
    if (fileAudio.files.length > 0) {
      const audioFile = fileAudio.files[0];
      formData.append('audio', audioFile);
    }
    if (fileVideo.files.length > 0) {
      const videoFile = fileVideo.files[0];
      formData.append('video', videoFile);
    }

    for (var pair of formData.entries()) {
    }

    axios.post("/user/create_post", formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(res => {
        const feed = res.data
        feed.created_at = 'now'
        setFeeds([feed, ...feeds]);
        setUpload(false)
      })
      .catch(err => { console.error(err); setUpload(false) })
    document.querySelector('#content').value = "";
    fileInput.value = "";
    fileAudio.value = "";
    fileVideo.value = "";
    document.getElementById('image-preview-name').innerText = ''
    document.getElementById('video-preview-name').innerText = ''
    document.getElementById('audio-preview-name').innerText = ''

    // Fetch all posts after sharing a new post
    // loadFeeds()

  }

  function get_self_stories(data, user) {
    const self_stories = []
    if (user.email in data.story_data) {
      self_stories.push(data.story_data[user.email])
    }
    return self_stories
  }

  function get_other_stories(data, user) {
    // remove user.email from the data return rest
    delete data.story_data[user.email];
    return data
  }

  function createAStory() {
    var modal = document.getElementById("addStorymyModal");
    modal.style.display = "flex";
  }

  function story_is_viewed(data) {
    let viewed = false
    var total_views = 0
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < data[i].viewed_by.length; j++) {
        if (user.email == data[i].viewed_by[j]) {
          total_views += 1
        }
      }
    }
    if (total_views == data.length) {
      viewed = true
    }
    return viewed

  }

  function createAddStory() {
    var img = document.createElement('img')
    img.src = '/add-story.png'
    img.className = 'add-story'
    img.id = 'add-story'
    img.title = "Add Story"
    img.addEventListener('click', function () {
      createAStory()
    }
    )

    document.getElementById('your_story').appendChild(img)

  }

  function create_self_story(status_content, data) {
    const outerDiv = document.createElement('div')
    outerDiv.id = 'your_story'
    const status_detail = document.createElement('div')
    const status_ring = document.createElement('div')
    const profile_picture = document.createElement('img')
    const your_story = document.createElement('div')
    if (user["image"] === null) {
      profile_picture.src = '/profilePicture-default.png'
    }
    else {
      profile_picture.src = user["image"]
    }

    profile_picture.className = 'app-element profile-picture default-profile-picture'
    your_story.innerHTML = t.your_story
    status_detail.className = 'app-element status-detail'
    status_ring.appendChild(profile_picture)
    status_detail.appendChild(status_ring)
    status_detail.appendChild(your_story)
    outerDiv.appendChild(status_detail)
    status_content.appendChild(outerDiv)
    const public_id = []
    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < data[i].length; j++) {
        public_id.push(data[i][j].story_public_id)
      }
    }
    status_detail.setAttribute('data-child', public_id.toString())

    if (data.length == 0) {
      status_ring.className = 'app-element status-ring-disabled'
      // status_detail.style.cursor = 'default'
      status_detail.addEventListener('click', function () {
        createAStory()
      });
    }
    else {
      // status_detail.style.cursor = 'pointer'
      status_ring.className = 'app-element status-ring'
      status_detail.addEventListener('click', function () {
        openStory(public_id.toString())
      });
    }
    createAddStory()
  }

  function create_stories(self_stories, other_stories) {
    const status_content = document.querySelector('.status-content')
    status_content.innerHTML = ''
    create_self_story(status_content, self_stories)
    // loop over other_stories and create story
    for (const [key, value] of Object.entries(other_stories.story_data)) {
      const outerDiv = document.createElement('div')
      const status_detail = document.createElement('div')
      const status_ring = document.createElement('div')
      const profile_picture = document.createElement('img')
      const your_story = document.createElement('div')
      if (value[0].user_profile_photo == null) {
        profile_picture.src = '/profilePicture-default.png'
      }
      else {
        profile_picture.src = value[0].user_profile_photo
      }

      profile_picture.className = 'app-element profile-picture default-profile-picture'
      your_story.innerHTML = value[0].username
      status_detail.className = 'app-element status-detail'
      status_ring.appendChild(profile_picture)
      status_detail.appendChild(status_ring)
      status_detail.appendChild(your_story)
      outerDiv.appendChild(status_detail)
      status_content.appendChild(outerDiv)
      const public_id = []
      for (let i = 0; i < value.length; i++) {
        public_id.push(value[i].story_public_id)
      }
      status_detail.setAttribute('data-child', public_id.toString())
      status_detail.addEventListener('click', function () {
        openStory(public_id.toString())
      });

      if (story_is_viewed(value)) {
        status_ring.className = 'app-element status-ring-disabled'
      }
      else {
        status_ring.className = 'app-element status-ring'
      }
    }
  }

  function openStory(public_id) {
    // Split the public_id into a list
    const public_id_list = public_id.split(',');
    const story_data = [];

    // Create an array of axios requests
    const requests = public_id_list.map(id => {
      var FormDataStory = new FormData();
      FormDataStory.append('token', token);
      FormDataStory.append('story_public_id', id);

      return axios.post("/user/viewStory", FormDataStory, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    });

    // Wait for all axios requests to complete
    Promise.all(requests)
      .then(responses => {
        responses.forEach(res => {
          story_data.push(res.data.story_data);
        });
        createScrolls(story_data.length);
        viewNextStory(0, story_data);
        if (story_data[0].user_email != user.email) {
          document.getElementById('delete').style.display = 'none';
        }
        else {
          document.getElementById('delete').style.display = 'block';
        }


        var modal = document.getElementById("myModal");
        modal.style.display = "flex";

        scrollbarStory(0, story_data.length, '0%');
        var current = 0;
        var last = story_data.length;
        var width = 0;
        let intervalId = setInterval(function () {
          width += 0.1;
          if (Math.floor(width) == 100) {
            width = 0;
            current += 1;

            if (current == last) {
              closeModal();
              clearAllIntervals();
              return;
            } else {
              scrollbarStory(current, last, width + '%');
              viewNextStory(current, story_data);
            }
          } else {
            scrollbarStory(current, last, width + '%');
            // viewNextStory(current, story_data);
          }
        }, 10);


      })
      .catch(err => {
        console.error(err);
      });
  }

  function clearAllIntervals() {
    let highestIntervalId = setInterval(() => { }, 100); // Create a dummy interval to get the highest ID
    for (let i = 0; i <= highestIntervalId; i++) {
      clearInterval(i);
    }
  }

  function scrollbarStory(current, last, width) {
    if (current == last) {
      closeModal();
    }
    document.getElementById('scroll_inner_' + current).style.width = width;
    document.getElementById('widthOfScroll').value = width;
    document.getElementById('currentOfStory').value = current;
    document.getElementById('lastOfStory').value = last;
  }

  function load_links() {
    var links = document.getElementById('links_to_user_socials')
    // check if in user data these links are present if yes then show them else dont. if no link is present then also hide the dropdown arrow
    if (user.facebook && !document.getElementById('facebook')) {
      var a = document.createElement('a')
      a.href = user.facebook
      a.target = '_blank'
      var i = document.createElement('i')
      i.className = 'app-element bx bxl-facebook-square'
      i.id = 'facebook'
      a.appendChild(i)
      links.appendChild(a)
    }
    if (user.instagram && !document.getElementById('instagram')) {
      var a = document.createElement('a')
      a.href = user.instagram
      a.target = '_blank'
      var i = document.createElement('i')
      i.className = 'app-element bx bxl-instagram-alt'
      i.id = 'instagram'
      a.appendChild(i)
      links.appendChild(a)
    }
    if (user.twitter && !document.getElementById('twitter')) {
      var a = document.createElement('a')
      a.href = user.twitter
      a.target = '_blank'
      var i = document.createElement('i')
      i.className = 'app-element bx bxl-twitter'
      i.id = 'twitter'
      a.appendChild(i)
      links.appendChild(a)
    }
    if (user.linkedin && !document.getElementById('linkedin')) {
      var a = document.createElement('a')
      a.href = user.linkedin
      a.target = '_blank'
      var i = document.createElement('i')
      i.className = 'app-element bx bxl-linkedin-square'
      i.id = "linkedin"
      a.appendChild(i)
      links.appendChild(a)
    }
    if (user.youtube && !document.getElementById('youtube')) {
      var a = document.createElement('a')
      a.href = user.youtube
      a.target = '_blank'
      var i = document.createElement('i')
      i.className = 'app-element bx bxl-youtube'
      i.id = 'youtube'
      a.appendChild(i)
      links.appendChild(a)
    }
    if (user.github && !document.getElementById('github')) {
      var a = document.createElement('a')
      a.href = user.github
      a.target = '_blank'
      var i = document.createElement('i')
      i.className = 'app-element bx bxl-github'
      i.id = 'github'
      a.appendChild(i)
      links.appendChild(a)
    }

    if (links.children.length <= 0) {
      document.querySelector('#dropdown_link').style.display = 'none'
      document.querySelector('#links_to_user_socials').style.display = 'none'
    }
  }

  function viewNextStory(current, story_data) {

    // if story data is not in json format convert it to json
    if (typeof story_data == 'string') {
      story_data = JSON.parse(story_data);
    }

    // setting user profile
    if (story_data[current].user_profile_photo == null) {
      document.getElementById('image_of_user').src = '/profilePicture-default.png';
    }
    else { document.getElementById('image_of_user').src = story_data[current].user_profile_photo; }
    // setting user name
    document.getElementById('nameStory').innerHTML = story_data[current].username;
    // setting created at
    document.getElementById('createdAt').innerHTML = story_data[current].created_at;
    // setting current story
    if (story_data[current].photo == null) {
      document.getElementById("blurbg1").style.display = 'none';
      document.getElementById("blurbg2").style.display = 'none';
      document.getElementById('currentStory').src = story_data[current].video;
    }
    else {
      document.getElementById("blurbg1").style.display = 'block';
      document.getElementById("blurbg2").style.display = 'block';
      document.getElementById("blurbg1").style.background = 'url(' + story_data[current].photo + ')';
      document.getElementById("blurbg2").style.background = 'url(' + story_data[current].photo + ')';
      document.getElementById('currentStory').src = story_data[current].photo;
    }
    // setting text
    if (story_data[current].text == null) {
      document.getElementById('text').style.display = 'none';
    }
    else {
      document.getElementById('text').style.display = 'block';
      document.getElementById('text').innerHTML = story_data[current].text;
    }
    document.getElementById('currentStoryId').value = story_data[current].story_public_id;
    document.getElementById('storyDataOfStory').value = JSON.stringify(story_data);


  }

  function pauseStory() {
    document.getElementById('pause').style.display = 'none';
    document.getElementById('play').style.display = 'block';
    clearAllIntervals()
  }

  function playStory() {
    document.getElementById('play').style.display = 'none';
    document.getElementById('pause').style.display = 'block';
    var width = document.getElementById("widthOfScroll").value;
    width = parseFloat(width);
    var current = document.getElementById("currentOfStory").value;
    current = parseInt(current);
    var last = document.getElementById("lastOfStory").value;
    last = parseInt(last);
    var story_data = document.getElementById("storyDataOfStory").value;
    story_data = JSON.parse(story_data);


    let intervalId = setInterval(function () {
      width += 0.1;
      if (Math.floor(width) == 100) {
        width = 0;
        current += 1;

        if (current == last) {
          closeModal();
          clearAllIntervals();
          return;
        } else {
          scrollbarStory(current, last, width + '%');
          viewNextStory(current, story_data);
        }
      } else {
        scrollbarStory(current, last, width + '%');
      }
    }, 10);
  }

  function createScrolls(n) {
    document.getElementById('scrollbarStory').innerHTML = ''
    for (let i = 0; i < n; i++) {
      var div = document.createElement('div')
      div.className = 'scroll'
      var div_inner = document.createElement('div')
      div_inner.className = 'scroll_inner'
      div_inner.id = 'scroll_inner_' + i
      div.appendChild(div_inner)
      document.getElementById('scrollbarStory').appendChild(div)
    }
  }

  function get_stories_for_user() {
    const formDataStory = new FormData();
    formDataStory.append('token', token);
    axios.post("/user/getStories", formDataStory, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(res => {
        const self_stories = get_self_stories(res.data, user)
        const other_stories = get_other_stories(res.data, user)
        create_stories(self_stories, other_stories)

      })
      .catch(err => { console.error(err); })
  }

  function closeModal() {
    document.getElementById('play').style.display = 'none';
    document.getElementById('pause').style.display = 'block';
    var modal = document.getElementById("myModal");
    modal.style.display = "none";
    clearAllIntervals();
    var modal2 = document.getElementById("addStorymyModal");
    modal2.style.display = "none";
    document.getElementById('uploadImgDiv').style.display = 'flex';
    document.getElementById('blurbg3').style.background = 'none';
    document.getElementById('blurbg4').style.background = 'none';
    document.getElementById('currentStoryAdd').style.display = 'none';
    document.getElementById('deleteStoryModal').style.display = 'none';
  }

  function uploadImageFromDevice() {
    // Create an input element
    const inputElement = document.createElement('input');
    // Set the type to file
    inputElement.type = 'file';
    // Accept only image files
    inputElement.accept = 'image/*';

    // Listen for the change event when the user selects a file
    inputElement.addEventListener('change', function (event) {
      const file = event.target.files[0];
      if (file) {
        // Create a FileReader to read the image file
        const reader = new FileReader();

        reader.onload = function (e) {
          // Get the image data URL and set it as the src of the img element
          const imgElement = document.getElementById('currentStoryAdd');

          if (imgElement) {
            imgElement.src = e.target.result;
            imgElement.style.display = 'block';
            document.getElementById('uploadImgDiv').style.display = 'none';
            document.getElementById('blurbg3').style.background = 'url(' + e.target.result + ')';
            document.getElementById('blurbg4').style.background = 'url(' + e.target.result + ')';
          }

        };

        // Read the file as a Data URL (base64 encoded string)
        reader.readAsDataURL(file);
      }
    });

    // Trigger the click event to open the file upload window
    inputElement.click();
  }

  function shareStory() {
    if (document.getElementById('currentStoryAdd').style.display == 'none') {
      toast.warn("Please add a Photo")
    }
    else {
      const fileInput = document.getElementById('currentStoryAdd')
      // check if filesize is greater than 1mb then return
      if (fileInput.src.length > 1048576) {
        toast.warn("File size should be less than 1MB")
        closeModal();
        return
      }

      const formData = new FormData();
      formData.append('token', token);
      formData.append('image', fileInput.src);
      if (document.getElementById('addText').value) {
        formData.append('text', document.getElementById('addText').value);
      }
      axios.post("/user/createStory", formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(res => {
          closeModal();
          get_stories_for_user();
          toast.success("Story Shared Successfully");
        })
        .catch(err => {
          console.error(err);
          closeModal();
          toast.error("Story Sharing Failed");
        })
    }
  }

  function DeleteStory() {
    var current_story_id = document.getElementById('currentStoryId').value;
    const formData = new FormData();
    formData.append('token', token);
    formData.append('story_public_id', current_story_id);
    axios.post("/user/deleteStory", formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then(res => {
        closeModal();
        get_stories_for_user();
        toast.success("Story Deleted Successfully");
      })
      .catch(err => {
        console.error(err);
        closeModal();
        toast.error("Story Deletion Failed");
      })


  }

  function DeleteStoryModal() {
    var modal = document.getElementById("deleteStoryModal");
    modal.style.display = "flex";

  }

  function nextStory() {
    document.getElementById('play').style.display = 'none';
    document.getElementById('pause').style.display = 'block';
    clearAllIntervals()
    var width = 0;
    var current = document.getElementById("currentOfStory").value;
    current = parseInt(current);
    document.getElementById("scroll_inner_" + current).style.width = "100%";
    current += 1;
    var last = document.getElementById("lastOfStory").value;
    last = parseInt(last);
    var story_data = document.getElementById("storyDataOfStory").value;
    story_data = JSON.parse(story_data);
    if (current == last) {
      closeModal();
      return;
    }
    viewNextStory(current, story_data);
    let intervalId = setInterval(function () {
      width += 0.1;
      if (Math.floor(width) == 100) {
        width = 0;
        current += 1;

        if (current == last) {
          closeModal();
          clearAllIntervals();
          return;
        } else {
          scrollbarStory(current, last, width + '%');
          viewNextStory(current, story_data);
        }
      } else {
        scrollbarStory(current, last, width + '%');
      }
    }, 10);


  }

  function prevStory() {
    document.getElementById('play').style.display = 'none';
    document.getElementById('pause').style.display = 'block';
    clearAllIntervals()
    var width = 0;
    var current = document.getElementById("currentOfStory").value;
    current = parseInt(current);
    document.getElementById("scroll_inner_" + current).style.width = "0%";
    current -= 1;
    var last = document.getElementById("lastOfStory").value;
    last = parseInt(last);
    var story_data = document.getElementById("storyDataOfStory").value;
    story_data = JSON.parse(story_data);
    if (current < 0) {
      closeModal();
      return;
    }
    viewNextStory(current, story_data);
    let intervalId = setInterval(function () {
      width += 0.1;
      if (Math.floor(width) == 100) {
        width = 0;
        current += 1;

        if (current == last) {
          closeModal();
          clearAllIntervals();
          return;
        } else {
          scrollbarStory(current, last, width + '%');
          viewNextStory(current, story_data);
        }
      } else {
        scrollbarStory(current, last, width + '%');
      }
    }, 10);

  }

  useEffect(() => {
    loadFeeds()
    load_links()
    get_stories_for_user()

  }, [])

  return (
    <div id="outer" >
      <div className="app-element content profile invisible-scrollbar" >

        <section className="app-element invisible-scrollbar" id="user">
          <img src="/logo.png" className="app-element logo" alt="FamSketch" />
          <img alt="profilePicture-default" src={user.image ? `${user.image}` : "/profilePicture-default.png"} className={`app-element profile-picture ${!user.image && 'default-profile-picture'}`}
            id="panel-profile-picture" />
          <h2 className="app-element name">{`${user.username}`}</h2>
          <div className="app-element social">
            <p className="app-element people icon-text-wrapper" title='Followers'>
              <i className="app-element bx bxs-group"></i>
              <span id="number">{followersCount}</span>
            </p>
            <Link to="{ name: 'user-id-calendar', params: userData }" className="app-element link">
              <i className="app-element bx bx-calendar"></i>
            </Link>
            <a href="" className="app-element function">
              <i className="app-element bx bx-heart"></i>
            </a>
            <a href="" className="app-element function">
              <i className="app-element bx bx-share-alt"></i>
            </a>
            <button className="app-element dropdown">
              <i onClick={handleDropdown} className="app-element bx bxs-down-arrow" id='dropdown_link'></i>
              <div className="app-element list hidden" id='links_to_user_socials'>

              </div>
            </button>
          </div>
          <div className="app-element info">
            <p className="app-element birth info"><span className="app-element tag">{t.born}:</span>{user.date_of_birth ? user.date_of_birth : "YYYY-MM-DD"}</p>
            <p className="app-element location info"><span className="app-element tag">{t.city}:</span> {user.city ? user.city : 'Košice'}</p>
            <p className="app-element relationship-status info"><span className="app-element tag">{t.status}:</span> {user.marital_status ? user.marital_status : 'Single'}</p>
          </div>

          <p className="app-element quote">{user.status ? user.status : 'We are memories for our loved ones.'}</p>
        </section>
        <section className="app-element center invisible-scrollbar">
          <div className='app-element status-content invisible-scrollbar' id='status-content'>

          </div>
          <div className="app-element pannel">
            <textarea className="app-element pannel-title invisible-scrollbar" id="content" placeholder={`${t.mind}`} />

            {/* <!-- Photo --> */}
            <Link className="app-element function icon-text-wrapper" id="photo-function" onClick={openFileUpload}>
              <i className="app-element bx bxs-image"></i>
              {t.photos}
            </Link>
            <input type="file" id="image-preview" className="hidden" accept="image/*" onChange={e => handleImageUpload(e)} />
            {/* <!-- Audio --> */}
            <Link className="app-element function icon-text-wrapper" id="audio-function" onClick={openAudioUpload}>
              <i className="app-element bx bxs-music"></i>
              {t.audio}
            </Link>
            <input type="file" id="audio-preview" className="hidden" accept="audio/*" onChange={e => handleAudioUpload(e)} />
            {/* <!-- Video --> */}
            <Link className="app-element function icon-text-wrapper" id="video-function" onClick={openVideoUpload}>
              <i className="app-element bx bxs-video"></i>
              {t.video}
            </Link>
            <input type="file" id="video-preview" className="hidden" accept="video/*" onChange={e => handleVideoUpload(e)} />

            {/* <!-- Share --> */}


            <button data-v-ad714b58="" className="app-element button" id="share-function" onClick={sharePost} data-nuxt-link>
              {upload ? t.uploading : t.share}
            </button>
            <p id='image-preview-name'></p>
            <p id='audio-preview-name'></p>
            <p id='video-preview-name'></p>
          </div>



          <div className="app-element" id="post" >{renderPosts(feeds)}{
            feeds.length > 0 && <article className='message'>
              <div className='message-content'>{t.feed}</div>
            </article>
          }</div>
        </section>
        <section className="app-element  advertising" id="">
          <div className='invisible-scrollbar'>

            <a href="#" >
              <img src={currentLanguage === 'EN' ? '/6.png' : '/5.png'}  className="app-element ad" alt="Ad" />
            </a>
            <a href="#" >
            <img className='app-element ad' alt='event-image' src={currentLanguage === 'EN' ? '/3.png' : '/4.png'} />
            </a>
            <a href="#" >
            <img className='app-element ad' alt='event-image' src={currentLanguage === 'EN' ? '/2.png' : '/1.png'} />
            </a>
            
          </div>
        </section>
      </div>

      <div id="myModal" className="modal">
        <div className="modal-content">
          <div id="blurbg1"></div>
          <div id="blurbg2"></div>
          <div className="modal-body">
            <div id="kaalabg">
              <div id="scrollbarStory">
                <div className="scroll">
                  <div className="scroll_inner"></div>
                </div>
                <div className="scroll">
                  <div className="scroll_inner"></div>
                </div>
              </div>
              <div id="optionsStory">
                <div id="left">
                  <img id='image_of_user' src="/LubosMincak.png" alt="" />
                  <p id="nameStory">LubosMincak</p>
                  <p id="createdAt">11h</p>
                </div>
                <div id="right">
                  <span className="play" id='play' title="Play" onClick={playStory}><img src="/play.png" alt="" /></span>
                  <span className="pause" id='pause' title='Pause' onClick={pauseStory}><img src="/pause.png" alt="" /></span>

                  <span className='delete' id='delete' title='Delete' onClick={DeleteStoryModal}><img src="/delete.png" alt="" /></span>
                  <span className="close" onClick={closeModal} title='Close'>&times;</span>
                </div>
              </div>
            </div>
            <img src="/temp.png" alt="" id="currentStory" />
            <div id="text">Hello this is me</div>
            <div id="nextStory" onClick={nextStory} title='Next Story'><img src="/next-arrow.png" alt="" /></div>
            <div id="prevStory" onClick={prevStory} title='Previous Story'><img src="/prev-arrow.png" alt="" /></div>
          </div>
        </div>
      </div>

      <div id="addStorymyModal" className="modal">
        <div className="modal-content">
          <div id="blurbg3"></div>
          <div id="blurbg4"></div>
          <div className="modal-body">
            <div id="kaalabg">

              <div id="optionsStory">
                <div id="left">
                  <img id='image_of_user' src={user.image ? `${user.image}` : "/profilePicture-default.png"} alt="" />
                  <p id="nameStory">{user.username}</p>
                </div>
                <div id="right">
                  <span className="close" onClick={closeModal} title='Close'>&times;</span>
                </div>
              </div>
            </div>
            <div id='uploadImgDiv'>
              <button id='uploadImage' onClick={uploadImageFromDevice}>{t.upload_image}</button>
            </div>
            <img src="/temp.png" alt="" id="currentStoryAdd" />
            <div id="text"><input type="text" id="addText" placeholder={`${t.some_text}`} /></div>
            <div id="sendStory" title='Share Story' onClick={shareStory}><img src="/send.png" alt="" /></div>
          </div>
        </div>
      </div>

      <div id="deleteStoryModal" className="modal">
        <div className="modal-content">
          <div className="modal-body">
            <p className="deleteText">{t.story_delete}</p>
            <div className="yes_no_delete">
              <button id='yes_button' onClick={DeleteStory}>{t.yes}</button>
              <button id='no_button' onClick={closeModal}>{t.no}</button>
            </div>
          </div>
        </div>
      </div>



      <input type="hidden" name="widthOfScroll" id="widthOfScroll" readOnly />
      <input type="hidden" name="currentOfStory" id="currentOfStory" readOnly />
      <input type="hidden" name="lastOfStory" id="lastOfStory" readOnly />
      <input type="hidden" name="storyDataOfStory" id="storyDataOfStory" readOnly />
      <input type="hidden" name="currentStoryId" id="currentStoryId" readOnly />
    </div>

  )
}

export default Profile
import React, { useState } from 'react'
import './Index.css'
import SideBar from './SideBar'
import ProfileBar from './ProfileBar'
import CenterPost from './CenterPost'
import EditProfile from './EditProfile'

function Index({acceptRequest,deleteRequest,unFollow,deleteFollower,likePost,unLikePost,userData,followers, followings, requests, refreshUser,refreshFFR, showProfileModel,showPostModel}) {
  const [editProfile, setEditProfile] = useState(false)

  return (
    <div className='app-element setting invisible-scrollbar'>
      <ProfileBar user={userData.user} setEditProfile={setEditProfile} followersCount={followers.length}/>
      {editProfile?
        <EditProfile setEditProfile={setEditProfile} refreshUser={refreshUser} user={userData.user}/>:
        <CenterPost likePost={likePost} unLikePost={unLikePost} posts={userData.posts} showPostModel={showPostModel} userData = {userData}/>
      }
      <SideBar acceptRequest={acceptRequest} deleteRequest={deleteRequest} unFollow={unFollow} deleteFollower={deleteFollower} showProfileModel={showProfileModel} followers={followers} followings={followings} requests={requests}/>
    </div>
  )
}

export default Index
import React, { useState, useEffect } from 'react';
import './FAQ.css'
import { useFAQData } from '../composables/FAQ-data'
import translations from '../locales/translations.json';

function FAQ() {
  const data = useFAQData();
  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  };
  const savedLanguage = getCookie('selectedLanguage');
  const initialLanguage = savedLanguage ? savedLanguage.toUpperCase() : undefined;

  const [currentLanguage, setCurrentLanguage] = useState(initialLanguage || 'EN');
  const [t, setT] = useState(translations[currentLanguage]);
  console.log(t.faq)
  useEffect(() => {
    // If no saved language, set English by default
    if (!savedLanguage) {
      document.cookie = `selectedLanguage=en; path=/`;
    }

    // Update translation based on the current language
    setT(translations[currentLanguage]);
  }, [currentLanguage, savedLanguage]);
  return (
    <div className="page-element content">
      <section className="page-element section faqlanding" id="landing">
        <header className="page-element speech dark-accent-text">
          <h1 className="page-element section-title">{t.FAQ.title}</h1>
          <p className="page-element section-subtitle">All You Need to Know About Using FamSketch</p>
        </header>
        <img className="page-element" src="/gathering.png" style={{ filter: "hue-rotate(213deg) sepia(0.73) saturate(3) invert(0.22) brightness(1.3) contrast(1.1)" }} />
        <main className="page-element form questions main">
          <h2 className="page-element title dark-accent-text">{t.tell_us_anything}</h2>
          <input className="page-element" type="email" placeholder={`${t.email}`} title={`${t.email_title}`} id="message-email-input" />
          <input className="page-element" type="text" placeholder={`${t.subject}`} title={`${t.subject_desc}`} id="message-subject-input" />
          <textarea className="page-element" rows="8" placeholder={`${t.content}`} title={`${t.content_title}`} id="message-content-input"></textarea>
          <button className="page-element button" type="submit" id="send-button">{t.send}</button>
        </main>
      </section>
      <div className="page-element section-separator"></div>
      <section className="page-element list" id="faqs">
        {
          data.map((object) =>
            <button className="page-element question" key={object.id} id={object.id}>
              <h2 className="page-element subject">{object.subject}</h2>
              <p className="page-element description">{object.description}</p>
              <p className="page-element answer">{object.answer}</p>
            </button>
          )
        }
      </section>
    </div>
  )
}

export default FAQ
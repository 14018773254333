import { useEffect, useState } from "react";
import './About.css';
import translations from '../locales/translations.json';

function About() {
    const getCookie = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
        return null;
    };

    const savedLanguage = getCookie('selectedLanguage');
    const initialLanguage = savedLanguage ? savedLanguage.toUpperCase() : 'EN';
    
    const [currentLanguage, setCurrentLanguage] = useState(initialLanguage);
    const [t, setT] = useState(translations[currentLanguage]);

    const getVideoUrl = () => {
        return currentLanguage === 'EN' 
            ? "https://www.youtube.com/embed/FEE4mZOE5zQ" 
            : "https://www.youtube.com/embed/fgxFh88mgXI?si=QUY9pWJmrIkGWlZQ";
    };

    const [videoUrl, setVideoUrl] = useState(getVideoUrl);

    useEffect(() => {
        if (!savedLanguage) {
            document.cookie = `selectedLanguage=en; path=/`;
        }
        
        setT(translations[currentLanguage]);
        setVideoUrl(getVideoUrl());
    }, [currentLanguage, savedLanguage]);

    return (
        <div className="page-element about_content content invisible-scrollbar">
            <section className="page-element section right-main" id="landing">
                <header className="page-element speech bright-accent-text">
                    <h1 className="page-element section-title" id="ii">{t.about_us}</h1>
                    <h3 className="page-element section-subtitle" id="iii">{t.goal}</h3>
                </header>

                <img alt="colleagues" src="/colleagues.png" className="page-element" id="landing-img" />
                <div className="page-element main"></div>
            </section>

            <section className="page-element" id="video-screen">
                <iframe className="page-element" title="FamSketch - Intro Video" src={videoUrl} id="video-introduction"></iframe>
            </section>

            <section class="page-element" id="team">
            <h2 class="page-element section-title bright-accent-text"><center>{t.who_is_famsketch}</center></h2>
            <div class="page-element people">
                <div class="page-element person" id="PeterLehotsky">
                    <img class="page-element" alt='Peter' src="/PeterLehotsky.png" />
                    <h2 class="page-element name">Peter Lehotský</h2>
                    <p class="page-element description">{t.author}</p>
                    <p class="page-element specification">{t.unix}</p>
                    <a class="page-element link" href="https://www.linkedin.com/in/plehotsky" target="_blank">{t.linkedin}</a>
                </div>
                <div class="page-element person" id="LubosMincak">
                    <img class="page-element" alt='Lubos' src="/LubosMincak.png" />
                    <h2 class="page-element name">Ľuboš Minčák</h2>
                    <p class="page-element description">{t.developer}</p>
                    <p class="page-element specification">C++ | QT & QML Expert | Windows | Max | Linux</p>
                    <a class="page-element link" href="https://www.linkedin.com/in/lubos-mincak-iiteam/" target="_blank">{t.linkedin}</a>
                </div>
                {/* <div class="page-element person" id="MarosMincak">
                    <img class="page-element" alt='Maros' src="/MarosMincak.png" />
                    <h2 class="page-element name">Maroš Minčák</h2>
                    <p class="page-element description">{t.backend_developer}</p>
                    <p class="page-element specification">Laravel | PHP | Database| WEB</p>
                    <a class="page-element link" href="https://www.linkedin.com/in/maro%C5%A1-min%C4%8D%C3%A1k-0236a3187/" target="_blank">{t.linkedin}</a>
                </div> */}
                <div class="page-element person" id="Ananya Vishnoi">
                    <img class="page-element" alt='Ananya Vishnoi' src="/ananya.png" />
                    <h2 class="page-element name">Ananya Vishnoi</h2>
                    <p class="page-element description">{t.developer}</p>
                    <p class="page-element specification">Figma | Web | AWS Marketing </p>
                    <a class="page-element link" href="https://www.linkedin.com/in/ananya-vishnoi/" target="_blank">{t.linkedin}</a>
                </div>
                <div class="page-element person" id="MatejRecky">
                    <img class="page-element" alt='MatejRecky' src="/MatejRecky.png" />
                    <h2 class="page-element name">Matej Récky</h2>
                    <p class="page-element description">{t.enterprise}</p>
                    <a class="page-element link" href="https://www.linkedin.com/in/matej-r%C3%A9cky-a0876613b/" target="_blank">{t.linkedin}</a>
                </div>
                <div class="page-element person" id="MatejKristan">
                    <img class="page-element" alt='MatejKristan' src="/MatejKristan.jpg" />
                    <h2 class="page-element name">Matej Kristan</h2>
                    <p class="page-element description">"{t.developer}</p>
                    <a class="page-element link" href="#">{t.linkedin}</a>
                    </div>
                    </div>
        </section>
    </div>
  );
}

export default About;

import React, { useEffect, useState } from 'react'
import './SearchProfile.css'
import translations from '../../../../locales/translations.json';

function SearchProfile({sendRequest, deleteRequest, unFollow, showProfileModel, searchResult}) {

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  };
  const savedLanguage = getCookie('selectedLanguage');
  const initialLanguage = savedLanguage ? savedLanguage.toUpperCase() : undefined;

  const [currentLanguage, setCurrentLanguage] = useState(initialLanguage || 'EN');
  const [t, setT] = useState(translations[currentLanguage]);

  useEffect(() => {
    // If no saved language, set English by default
    if (!savedLanguage) {
      document.cookie = `selectedLanguage=en; path=/`;
    }

    // Update translation based on the current language
    setT(translations[currentLanguage]);
  }, [currentLanguage, savedLanguage]);


    
  return (
    <section className='app-element center invisible-scrollbar'>
      {searchResult.length === 0 && (
        <div className='pannel'>
          <p>{t.connecting}</p>
        </div>
      )}
      {searchResult.map((user, index) => {
        return (
          <div className='pannel' key={index}>
            <div className='user-profile' onClick={() => showProfileModel(null, user.email)}>
              <img className='profile-picture' src={user.image || '/profilePicture-default.png'} alt="image"/>
              <div className='user-details'>
                <p>{`${user.first_name} ${user.last_name}`}</p>
                <p>{user.email}</p>
              </div>
            </div>
            <div>
              {user.connection === 'none' ? (
                <button onClick={() => sendRequest(user.email)}>{t.follow}</button>
              ) : user.connection === 'requested' ? (
                <button onClick={() => deleteRequest(user.email, true)}>{t.cancel_request}</button>
              ) : (
                <button onClick={() => unFollow(user.email)}>{t.unfollow}</button>
              )}
            </div>
          </div>
        )
      })}
    </section>
  )
}

export default SearchProfile